import EntityStructure from './entity-structure';
import self from '../..';

const { Entity } = self.app.modules.dataStore;

const CornerStructure = EntityStructure.$extend({
    __name__: 'corner',

    __init__(params) {
        this.$super(params);
        this.initSnapping();
    },

    __include__: [self.app.modules.snapping.SnappableMixin.prototype],
});
Entity.$register(CornerStructure);
export default CornerStructure;
