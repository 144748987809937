module.exports = {
    name: 'data-store',
    requires: [],

    config: {
        ignoreToSerializePaths: [],
        orderingUnserialByPath: ['/groups/default', '/groups'],
    },

    load() {
        const DataStore = require('./src/data-store').default; // eslint-disable-line global-require
        return new DataStore();
    },

    unload() {
        // pass
    },
};
