import EntityStructure from './entity-structure';
import self from '../..';

const { Entity } = self.app.modules.dataStore;

const BeTrussSquareStructure = EntityStructure.$extend({
    __name__: 'betruss-square',

    __init__(params) {
        this.$super(params);
        this.initSnapping();
        this.initOptions();
    },

    __include__: [self.app.modules.snapping.SnappableMixin.prototype, self.app.modules.optionManager.OptionableMixin.prototype],
});
Entity.$register(BeTrussSquareStructure);
export default BeTrussSquareStructure;
