import { Geometry, VertexData, BoundingInfo, Material, Mesh, Scene } from '@babylonjs/core';
import { OBJExport } from '@babylonjs/serializers';

import { saveAs } from 'file-saver';
import { v4 as uuid } from 'uuid';

const ObjHelper = {
    tempMeshes: [],

    scale: 1000,

    usedMaterialsClone: {},

    copyMeshForOBJ(mesh, exportScene) {
        if (!this.usedMaterialsClone[mesh.material.name]) {
            this.usedMaterialsClone[mesh.material.name] = mesh.material.clone();
        }
        const mat = this.usedMaterialsClone[mesh.material.name];

        const copy = new Mesh(mesh.name, exportScene);
        copy.material = mat;
        mesh.geometry.applyToMesh(copy);
        copy.makeGeometryUnique();
        copy.parent = mesh.parent;
        if (mesh.rotationQuaternion) {
            copy.rotationQuaternion = mesh.rotationQuaternion.clone();
        } else {
            copy.rotation = mesh.rotation.clone();
        }
        copy.scaling = mesh.scaling.scale(this.scale);
        copy.setAbsolutePosition(mesh.absolutePosition.clone().scale(this.scale));
        copy.computeWorldMatrix(true);
        copy.sideOrientation = Material.ClockWiseSideOrientation;
        copy.bakeCurrentTransformIntoVertices();
        copy.parent = null;
        return copy;
    },

    /**
     * mesh.sideOrientation exist on from babylonjs 7.11.0. I used it to fix the issue with the normals and for the future babylonjs upgrade
     * @see https://doc.babylonjs.com/breaking-changes#7110
     */
    exportToObj({ meshes, engine, directDownload = false, exportDescendants = true }) {
        const sceneForExport = new Scene(engine);
        meshes.forEach((mesh) => {
            this.tempMeshes.push(this.copyMeshForOBJ(mesh, sceneForExport));
            if (exportDescendants) {
                const children = mesh.getChildMeshes(false);
                children.forEach((childMesh) => {
                    this.tempMeshes.push(this.copyMeshForOBJ(childMesh, sceneForExport));
                });
            }
        });

        const obj = OBJExport.OBJ(this.tempMeshes, false, null, true);

        if (directDownload) {
            const blob = new Blob([obj], { type: 'text/plain' });
            saveAs(blob, 'scene.obj');
        }

        this.tempMeshes.forEach((mesh) => {
            mesh.dispose();
        });

        sceneForExport.dispose();

        this.tempMeshes = [];
        return obj;
    },

    /**
     * Clone the current geometry into a new geometry
     * It's the same as the clone function of Geometry
     * but it includes this fix : https://github.com/BabylonJS/Babylon.js/pull/5643
     *
     * @param id defines the unique ID of the new geometry
     * @returns a new geometry object
     */
    copyGeometry(baseGeom) {
        const vertexData = new VertexData();
        vertexData.indices = [];
        const indices = baseGeom.getIndices();
        if (indices) {
            for (let index = 0; index < indices.length; index++) {
                vertexData.indices.push(indices[index]);
            }
        }
        let updatable = false;
        let stopChecking = false;
        Object.keys(baseGeom._vertexBuffers).forEach((kind) => {
            // using slice() to make a copy of the array and not just reference it
            const data = baseGeom.getVerticesData(kind);
            if (data) {
                if (data instanceof Float32Array) {
                    vertexData.set(new Float32Array(data), kind);
                } else {
                    vertexData.set(data.slice(0), kind);
                }
                if (!stopChecking) {
                    const vb = baseGeom.getVertexBuffer(kind);

                    if (vb) {
                        updatable = vb.isUpdatable();
                        stopChecking = !updatable;
                    }
                }
            }
        });
        const geometry = new Geometry(uuid(), baseGeom._scene, vertexData, updatable);
        geometry.delayLoadState = baseGeom.delayLoadState;
        geometry.delayLoadingFile = baseGeom.delayLoadingFile;
        geometry._delayLoadingFunction = baseGeom._delayLoadingFunction;
        let kind;
        // eslint-disable-next-line guard-for-in, no-restricted-syntax
        for (kind in baseGeom._delayInfo) {
            geometry._delayInfo = geometry._delayInfo || [];
            geometry._delayInfo.push(kind);
        }
        // Bounding info
        geometry._boundingInfo = new BoundingInfo(baseGeom._extend.minimum, baseGeom._extend.maximum);
        return geometry;
    },
};

export default ObjHelper;
