import { Material, Mesh, Scene } from '@babylonjs/core';

class GlbExporter {
    constructor() {
        this.usedMaterialsClone = {};
        this.sceneForExport = null;
        this.meshesCopied = [];
        this.scale = 1000;
    }

    copyMeshForGLB(mesh, exportScene) {
        let mat = this.usedMaterialsClone[mesh.material.name];
        if (!this.usedMaterialsClone[mesh.material.name]) {
            this.usedMaterialsClone[mesh.material.name] = mesh.material.clone();
            mat = this.usedMaterialsClone[mesh.material.name];
            if (mat.lightmapTexture) {
                mat.albedoTexture = mat.lightmapTexture;
            }
        }

        const copy = new Mesh(mesh.name, exportScene);
        mesh.geometry.applyToMesh(copy);
        copy.makeGeometryUnique();
        copy.parent = mesh.parent;

        if (mesh.rotationQuaternion) {
            copy.rotationQuaternion = mesh.rotationQuaternion.clone();
        } else {
            copy.rotation = mesh.rotation.clone();
        }
        copy.scaling = mesh.scaling.scale(this.scale);
        copy.setAbsolutePosition(mesh.absolutePosition.clone().scale(this.scale));
        copy.computeWorldMatrix(true);

        copy.material = mat;
        copy.sideOrientation = Material.ClockWiseSideOrientation;
        copy.bakeCurrentTransformIntoVertices();
        copy.parent = null;
        return copy;
    }

    prepareNewScene(engine, meshes) {
        if (!this.sceneForExport) {
            this.sceneForExport = new Scene(engine);
        }
        meshes.forEach((mesh) => {
            this.meshesCopied.push(this.copyMeshForGLB(mesh, this.sceneForExport, mesh));
            mesh.getChildMeshes().forEach((child) => {
                this.meshesCopied.push(this.copyMeshForGLB(child, this.sceneForExport, mesh));
            });
        });
    }

    dispose() {
        this.meshesCopied.forEach((mesh) => {
            mesh.dispose();
        });
        this.meshesCopied = [];
    }
}

export default GlbExporter;
