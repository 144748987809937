import EntityStructure from './entity-structure';
import self from '../..';

const { Entity } = self.app.modules.dataStore;

const FlooringConnectorStructure = EntityStructure.$extend({
    __name__: 'flooring-connector',

    __init__(params) {
        this.$super(params);
    },
});
Entity.$register(FlooringConnectorStructure);
export default FlooringConnectorStructure;
