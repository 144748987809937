import EntityStructure from './entity-structure';
import self from '../..';

const { Entity } = self.app.modules.dataStore;

const PopinStructure = EntityStructure.$extend({
    __name__: 'popin',

    __init__(params) {
        this.$super(params);
        this.initSnapping();
    },

    __include__: [self.app.modules.snapping.SnappableMixin.prototype],
});
Entity.$register(PopinStructure);
export default PopinStructure;
