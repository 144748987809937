import EntityStructure from './entity-structure';
import self from '../..';

const { Entity } = self.app.modules.dataStore;

const LedskinStructure = EntityStructure.$extend({
    __name__: 'ledskin',

    __init__(params) {
        this.$super(params);
        this.initMeasurement();
    },

    __include__: [self.app.modules.measurementManager.MeasurableMixin.prototype],
});
Entity.$register(LedskinStructure);
export default LedskinStructure;
