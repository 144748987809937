import self from '../..';
import EntitiesData from '../../../../entities-data';
import cu from 'vendors/class-utils';
import { Quaternion, Vector3 } from '@babylonjs/core';

import { v4 as uuid } from 'uuid';

const { Entity } = self.app.modules.dataStore;

const {
    app: {
        modules: {
            optionManager: { ColorableMixin },
            meshManager: { meshController },
        },
        events: { emit },
    },
} = self;

/**
 * @class bematrix.EntityStructure
 */
const EntityStructure = Entity.$extend({
    isEntity: true,

    __name__: 'entity',

    __init__(params) {
        // dynamic parameters
        this.initializing = false;
        this.mesh = null;
        this.material = null;
        this.destroyed = false;
        this.asynchronousJobs = [];
        this.isSelected = false;
        this.$data.group = null;

        // serialized parameters
        this.$data.position = Vector3.Zero();
        this.$data.rotationQuaternion = Quaternion.Identity();
        this.$data.rotationQuaternion.w = 1;
        this.$data.ref = null;
        this.$data.materialId = 'default';
        this.$data.height = 0;
        this.$data.width = 0;
        this.$data.depth = 0;
        this.$data.id = uuid();
        this.$data.groupId = null;
        this.$data.category = null;
        this.$data.subCategory = null;
        this.$data.visible = true;

        this.$super(params);
    },

    // Includes all the mixins that an entity can implement based on a catalog info
    __include__: [ColorableMixin.prototype],

    handleInitialRotation() {
        if (this.subCategory === 'CORNER PROFILES' || this.subCategory === 'STRUCTURAL PROFILES') {
            this.mesh.rotate(Vector3.Right(), -Math.PI / 2);
        } else if (this.subCategory === 'COVER PROFILES') {
            this.mesh.rotate(Vector3.Right(), Math.PI / 2);
            this.mesh.rotate(Vector3.Forward(), Math.PI / 2);
        } else {
            this.mesh.rotate(Vector3.Forward(), Math.PI);
        }

        this.updateRotation();
    },

    /**
     * Clone the entity and set some of this properties according to the values in arguments
     * @param {*} cloneData
     */
    clone(cloneData = null) {
        const duplicatedStructure = this.$super();

        // Set some custom duplication properties after the mesh to be created
        if (cloneData) {
            if (cloneData.position) {
                duplicatedStructure.setPosition(cloneData.position);
            }
            if (cloneData.rotationQuaternion) {
                duplicatedStructure.setRotationQuaternion(cloneData.rotationQuaternion);
            }
        }
        return duplicatedStructure;
    },

    isPositionEqualToAbsolutePivotPoint() {
        return this.mesh.getAbsolutePivotPoint().equals(this.mesh.position);
    },

    setPosition(pos) {
        this.$data.position = pos;
        if (this.mesh) {
            this.mesh.position = pos.clone(); // For serialization
            this.mesh.computeWorldMatrix(true);
        }
    },

    getPosition() {
        '@serializer babylon-vector3';

        return this.$data.position;
    },

    getVisible: cu.getter,
    setVisible: cu.setter,

    onDrag() {
        this.updatePosition();
    },

    updatePosition() {
        this.mesh.computeWorldMatrix(true);
        this.$data.position = this.mesh.absolutePosition.clone();
    },

    getRotationQuaternion() {
        '@serializer babylon-quaternion';

        return this.$data.rotationQuaternion;
    },

    setRotationQuaternion(rot) {
        this.$data.rotationQuaternion = rot;
        if (this.mesh) {
            this.mesh.rotationQuaternion = rot.clone();
            this.mesh.computeWorldMatrix(true);
        }
    },

    updateRotation() {
        this.mesh.computeWorldMatrix(true);
        this.mesh.getWorldMatrix().decompose(null, this.$data.rotationQuaternion);
    },

    updateDynamicParameters() {
        if (this.mesh) {
            this.updatePosition();
            this.updateRotation();
            this.mesh.refreshBoundingInfo();
        }
    },

    getBoundingBox() {
        '@serializable false';

        return self.app.modules.meshManager.meshUtility.GetBoundingBox(this.mesh);
    },

    getId: cu.getter,

    getRef: cu.getter,
    setRef(ref) {
        this.$data.ref = ref;

        if (EntitiesData.REFS_WITHOUT_OPTIONS[ref]) {
            this.isOptionable = false;
        }
    },

    getHeight: cu.getter,
    setHeight: cu.setter,

    getWidth: cu.getter,
    setWidth: cu.setter,

    getDepth: cu.getter,
    setDepth: cu.setter,

    getMaterialId: cu.getter,
    setMaterialId(materialId) {
        this.$data.materialId = materialId;
        if (this.mesh) {
            meshController.applyMaterialToMesh(this.mesh, materialId, this.ref, null);
        }
    },

    getGroup() {
        '@serializable false';

        return this.$data.group;
    },
    setGroup: cu.setter,

    getGroupId: cu.getter,
    setGroupId: cu.setter,

    getCategory: cu.getter,
    setCategory: cu.setter,

    getSubCategory: cu.getter,
    setSubCategory: cu.setter,

    /**
     * All the entities stored in the data store must implement
     * a destroy function, to destroy them conveniently
     */
    destroy() {
        Promise.all(this.asynchronousJobs).then(() => {
            if (this.mesh) {
                this.mesh.dispose();
            }

            this.destroyed = true;

            emit('mesh-disposed', this);
        });
    },

    /**
     * freeze the world matrix of the entity's mesh and of all its children
     * The freeze also compute the world matrix immediately
     */
    freeze() {
        if (this.mesh) {
            this.mesh.freezeWorldMatrix();
            this.mesh
                .getChildren((node) => node.freezeWorldMatrix, false)
                .forEach((child) => {
                    child.freezeWorldMatrix();
                });
        }
    },

    /**
     * unfreeze the world matrix of the entity's mesh and of all its children
     */
    unfreeze() {
        if (this.mesh) {
            this.mesh.unfreezeWorldMatrix();
            this.mesh
                .getChildren((node) => node.freezeWorldMatrix, false)
                .forEach((child) => {
                    child.unfreezeWorldMatrix();
                });
        }
    },
});

Entity.$register(EntityStructure);
export default EntityStructure;
