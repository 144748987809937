import EntityStructure from './entity-structure';
import self from '../..';
import cu from 'vendors/class-utils';

const { Entity } = self.app.modules.dataStore;

const MotionskinStructure = EntityStructure.$extend({
    __name__: 'motionskin',

    __init__(params) {
        this.$super(params);
        this.initOptions();
        this.$data.isMotionskin = true;
        this.$data.materialId = 'motionskin-material';
    },

    __include__: [self.app.modules.optionManager.OptionableMixin.prototype],

    getIsMotionskin: cu.getter,
});
Entity.$register(MotionskinStructure);
export default MotionskinStructure;
