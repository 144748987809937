import EntityStructure from './entity-structure';
import self from '../..';

const { Entity } = self.app.modules.dataStore;

const CoverStructure = EntityStructure.$extend({
    __name__: 'cover',

    __init__(params) {
        this.$super(params);
        this.coverThickness = 0.0085;
        this.initSnapping();
    },

    __include__: [self.app.modules.snapping.SnappableMixin.prototype],
});
Entity.$register(CoverStructure);
export default CoverStructure;
