import self from '..';
import config from 'defaultConfig';
import jwtDecode from 'jwt-decode';

/**
 * Used to manage ObsidianConfig at the initialization
 */
const InitUtility = {
    init() {
        this.isConfigurationLoaded = false;
        this.initConfig();
        this.initLanguage();
    },

    /**
     * Initialize the app configuration by adapting with the config given in the iframeAPI
     */
    initConfig() {
        const iframeConfig = self.app.modules.iframeApi.$class.$getConfig();
        Object.keys(config).forEach((key) => {
            const property = iframeConfig[key];
            if (!property) {
                return;
            }
            if (key === 'projectName') {
                config.projectName = decodeURIComponent(property);
            } else if (key === 'versionName') {
                config.versionName = decodeURIComponent(property);
            } else {
                config[key] = property;
            }
        });

        if (process.env.WEBSITE_URL) {
            config.websiteUrl = process.env.WEBSITE_URL;
        }
        if (process.env.AMAZON_S3_URL) {
            config.amazonS3Url = process.env.AMAZON_S3_URL;
        }

        this.initLocalesVariables();
        this.initCatalogUrl();
        this.initMeshesAndThumbsUrl();
        this.initProjectsUrl();
        this.initTemplatesUrl();
        this.initGroupsUrl();

        const token = jwtDecode(config.token);
        config.email = token.email;
        config.roles = token.roles ? token.roles : [];
        config.plans = token.plans ? token.plans : [];
        config.mode = token.mode ? token.mode : '';
        this.isConfigurationLoaded = true;
        self.app.events.emit('configuration-loaded');
    },

    /**
     * Init the languages used in the app
     */
    initLanguage() {
        // Add fr_FR Catalog
        const catalogs = require('translationsCatalog');
        self.app.modules.stonejs.addCatalogs(catalogs);
        self.app.modules.stonejs.setBestMatchingLocale(config.language);
    },

    initLocalesVariables() {
        config.isLocalCatalogUrl = process.env.IS_LOCAL_CATALOG_URL === 'true';
        config.isLocalProjectsUrl = process.env.IS_LOCAL_PROJECTS_URL === 'true';
        config.isLocalTemplatesUrl = process.env.IS_LOCAL_TEMPLATES_URL === 'true';
        config.isLocalGroupsObjectsUrl = process.env.IS_LOCAL_GROUPS_OBJECTS_URL === 'true';
        config.isLocalMeshesUrl = process.env.IS_LOCAL_MESHES_URL === 'true';
    },

    initCatalogUrl() {
        config.catalogUrl = `${process.env.CATALOG_URL ? process.env.CATALOG_URL : config.websiteUrl}/api/catalog/`;
        if (config.isLocalCatalogUrl) {
            config.catalogUrl = '/assets/api/catalog.json';
        }
    },

    initMeshesAndThumbsUrl() {
        config.meshesUrl = config.amazonS3Url;
        if (config.isLocalMeshesUrl) {
            config.meshesUrl = '/assets';
        }

        config.thumbnailsUrl = `${config.meshesUrl}`;
    },

    initProjectsUrl() {
        config.projectsUrl = `${config.websiteUrl}`;
        if (config.isLocalProjectsUrl) {
            config.projectsUrl = '/assets';
        }
    },

    initTemplatesUrl() {
        config.templatesUrl = `${config.websiteUrl}/api/templates/`;
        if (config.isLocalTemplatesUrl) {
            config.templatesUrl = '/assets/api/templates.json';
        }
    },

    initGroupsUrl() {
        config.groupsObjectsUrl = `${config.websiteUrl}/api/group-objects/`;
        if (config.isLocalGroupsObjectsUrl) {
            config.groupsObjectsUrl = '/assets/api/group-objects.json';
        }
    },
};
export default InitUtility;
