import self from '../..';

const { Entity } = self.app.modules.dataStore;

const GridStructre = Entity.$extend({
    __name__: 'configuration',

    /**
     * @class bematrix.EntityStructure
     */
    __init__(params) {
        this.$data.gridHeight = null;
        this.$data.gridWidth = null;
        this.$super(params);
    },

    getGridHeight() {
        return this.$data.gridHeight;
    },

    setGridHeight(height) {
        this.$data.gridHeight = height;
    },

    getGridWidth() {
        return this.$data.gridWidth;
    },

    setGridWidth(width) {
        this.$data.gridWidth = width;
    },
});

Entity.$register(GridStructre);
export default GridStructre;
