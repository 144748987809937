import self from '../../index';
import html2canvas from 'html2canvas';

const {
    app: { config },
} = self;

export default {
    /**
     * Returns an screenshot of a DOM element
     * @param {HTMLElement} htmlElement
     * @param {Number} scale how much the resulting image will be scaled,
     * 1 means the image will have the screen size
     * @param {Boolean} transparentBackground when there is nothing on a pixel,
     * should it be transparent
     * @returns {Promise(image)} the promise returns a base64 encoded image
     */
    getScreenshotFromHtml(htmlElement, scale = 1, transparentBackground = false) {
        return Promise.resolve(
            html2canvas(htmlElement, {
                logging: config.get('@obsidian.debug'),
                scale,
                backgroundColor: transparentBackground ? null : '#ffffff',
            }),
        ).then((htmlCanvas) => htmlCanvas.toDataURL('image/png', 1));
    },
};
