/* eslint-disable max-classes-per-file */

import { RESIZABLE_CONSTRAINTS_TEMPLATES } from './resizable-constraints-templates';
import self from '..';
import DebugHelper from '../../../helpers/console-helper';
import ConsoleHelper from '../../../helpers/console-helper';
import config from 'defaultConfig';
import RightsHelper from 'helpers/rights-helper';

/**
 * Formalized catalog item
 * => contains base parameters to create the needed structure
 */
class CatalogItem {
    constructor(options) {
        if (options.product.isConnector) {
            this.__name__ = 'connector';
        } else if (options.category.toUpperCase() === 'FRAMES') {
            this.__name__ = 'frame';
        } else if (options.subCategory.toUpperCase().includes('LEDSKIN')) {
            this.__name__ = 'ledskin';
        } else if (options.subCategory.toUpperCase().includes('SIDELED LIGHTBOX')) {
            this.__name__ = 'sideled-lightbox';
        } else if (options.subCategory.toUpperCase().includes('BACKLED LIGHTBOX')) {
            this.__name__ = 'backled-lightbox';
        } else if (options.category.toUpperCase().includes('MOTIONSKIN')) {
            this.__name__ = 'motionskin';
        } else if (options.subCategory.toUpperCase().includes('COVER')) {
            this.__name__ = 'cover';
        } else if (options.subCategory.toUpperCase().includes('CORNER PROFILES')) {
            this.__name__ = 'corner';
        } else if (options.subCategory.toUpperCase().includes('FLOORING CONNECTORS')) {
            this.__name__ = 'flooring-connector';
        } else if (options.category.toUpperCase().includes('DOOR FRAMES')) {
            this.__name__ = 'door';
        } else if (
            options.subCategory.toUpperCase().includes('TRACK LIGHT START') ||
            options.subCategory.toUpperCase().includes('TRACK LIGHT EXTENSION')
        ) {
            this.__name__ = 'tracklight';
        } else if (options.subCategory.toUpperCase().includes('POP-IN')) {
            this.__name__ = 'popin';
        } else if (options.subCategory.toUpperCase().includes('BETRUSS B310')) {
            this.__name__ = 'betruss-b30';
        } else if (options.subCategory.toUpperCase().includes('BETRUSS SQUARE')) {
            this.__name__ = 'betruss-square';
        } else if (options.subCategory.toUpperCase().includes('STRUCTURAL PROFILES')) {
            this.__name__ = 'structural';
        } else {
            this.__name__ = 'entity';
        }
        this.isAvailable = options.isAvailable;
        this.category = options.category;
        this.subCategory = options.subCategory;

        // Put the properties from the catalog into the object
        Object.assign(this, options.product);
    }
}

/**
 * Catalog Manager : load and store the product list
 * Launch the loading of geometries
 */
export default class CatalogManager {
    /**
     * At creation : fetch the product infos json file and register them in the catalog
     * @class beMatrix.CatalogManager
     */
    constructor() {
        this.token = self.app.modules.iframeApi.$class.$getConfig().token || config.token;

        this.materialsUrl = 'assets/data/materials.json';

        this.catalog = null;
        this.vueData = { catalog: null, templates: null, groupedObjects: null };
        this.products = {};
        ConsoleHelper.expose('products', this.products);
        this.resizableConstraints = {};
        this.fetchObsidianModules();
        self.app.events.on('@obsidian-engine.engine-ready', () => {
            this.RequestManager.getJson(this.materialsUrl)
                .then((data) => {
                    this.loadMaterials(data);
                })
                .then(() => this.refreshTemplateCatalog())
                .then(() => this.refreshGroupObjectsCatalog())
                .then(() => {
                    if (config.isLocalCatalogUrl) {
                        return this.RequestManager.getJson(config.catalogUrl);
                    }
                    if (RightsHelper.isRoleDesigner()) {
                        return this.RequestManager.getJson(config.catalogUrl, true);
                    }
                    return this.RequestManager.getJson(config.catalogUrl);
                })
                .then((catalog) => {
                    this.loadCatalog(catalog);
                })
                .catch((err) => {
                    self.app.log.error('Error loading the products list :', err);
                });
        });
        self.app.modules.iframeApi.addApiMethod('refreshTemplateCatalog', () => this.refreshTemplateCatalog());
        self.app.modules.iframeApi.addApiMethod('refreshGroupObjectsCatalog', () => this.refreshGroupObjectsCatalog());

        if (process.env.NODE_ENV === 'development') {
            DebugHelper.expose('catalog', {
                json: () => this.catalog,
                products: this.products,
            });
        }
    }

    /** ""
     * Check that we can access all the product and then load them
     * @param {Object} jsonCatalog a json referencing all the products
     */
    loadCatalog(jsonCatalog) {
        this.catalog = jsonCatalog;

        this.catalog.forEach((category) => {
            category.groups.forEach((subcategory) => {
                let { products } = subcategory;
                const indexesToRemove = [];
                const newProducts = [];
                // TODO : Remove patch //
                // This is patch to fix doctrine bug at https://github.com/symfony/symfony/issues/37041
                // The problem is that empty collections become empty objects and not arrays
                if (!Array.isArray(products)) {
                    products = [];
                }
                // End of patch
                products.forEach((product) => {
                    product.thumbnailUrl = `${product.url}.jpg`;
                    product.url = product.url.concat(config.fileType);
                    this.registerProduct(product, true, category.name, subcategory.name);
                    if (product) {
                        if (product.showInCatalog && !subcategory.showInCatalog) {
                            subcategory.showInCatalog = true;
                            category.showInCatalog = true;
                        }
                        this.addResizableConstraints(product, subcategory.name, category.name);
                        product.isAvailable = true;
                    }
                });
                if (newProducts.length) {
                    // We sort the indexes from the highest to the lowest to avoid
                    // Complication in the loop with the splice method
                    const sortFunction = (a, b) => -(a - b);
                    indexesToRemove.sort(sortFunction);
                    indexesToRemove.forEach((index) => {
                        subcategory.products.splice(index, 1);
                    });
                    subcategory.products = subcategory.products.concat(newProducts);
                }
            });
        });
        this.initializeCatalog();
    }

    /**
     * Load the templates in the catalog from a given json (templateJson)
     * @param {String|JSON} templateJson - The JSON with the infos of the templates
     */
    loadTemplates(templateJson) {
        this.templates = templateJson;
        this.updateVueData();
    }

    /**
     * Load the grouped objects in the catalog from a given json (templateJson)
     * @param {String|JSON} groupedObjectJson - The JSON with the infos of the grouped objects
     */
    loadGroupedObject(groupedObjectJson) {
        this.groupedObjects = groupedObjectJson;
        this.updateVueData();
    }

    /**
     * Setup catalog vue
     * Throws the catalog-initialized event
     */
    initializeCatalog() {
        this.sortCatalog();
        this.catalogLoaded = true;
        this.updateVueData();
        self.app.log.info('Bematrix geometries loaded');
        self.app.events.emit('catalog-initialized');
    }

    /**
     * Used to sort the catalog by the ref (numerical and alphabetical order)
     */
    sortCatalog() {
        this.catalog.forEach((category) => {
            category.groups.forEach((subcategory) => {
                // TODO : Remove patch //
                // This is patch to fix doctrine bug at https://github.com/symfony/symfony/issues/37041
                // The problem is that empty collections become empty objects and not arrays
                if (!Array.isArray(subcategory.products)) {
                    return;
                }
                subcategory.products.sort((p1, p2) => p1.ref.localeCompare(p2.ref));
            });
        });
    }

    /**
     * Used to load a list of given materials
     * @param {JSON} materials - the materials list to load
     */
    loadMaterials(materials) {
        this.MaterialManager.initializeMaterialLibrary(materials);
        self.app.log.info('Bematrix materials loaded');
        self.app.events.emit('materials-loaded');
    }

    /**
     * Assign to the controller the obsidian modules needed
     */
    fetchObsidianModules() {
        this.RequestManager = self.app.modules.requestManager.controller;
        /**
         * @type bematrix.MeshManager */
        this.MeshManager = self.app.modules.meshManager.meshController;
        /**
         * @type bematrix.MaterialManager */
        this.MaterialManager = self.app.modules.materialManager;
        /**
         * @type obsidianjs.DataStore */
        this.dataStore = self.app.modules.dataStore;
    }

    /**
     * Updates the datas used in Vue UI
     */
    updateVueData() {
        this.vueData.catalog = this.catalog;
        this.vueData.templates = this.templates;
        this.vueData.groupedObjects = this.groupedObjects;
    }

    /**
     * Return true if the product could be accessed
     * @param {*} localUrl url of the resource
     * @returns {boolean}
     */
    checkLocalProductAvailability(productUrl) {
        return this.RequestManager.doRequest(`/assets/${productUrl}`)
            .catch((err) => {
                self.app.log.error('Error loading the product :', err);
                return null;
            })
            .then((buff) => {
                if (buff) {
                    return true;
                }
                return false;
            });
    }

    /**
     * Run a request to get the JSON infos for the current user's templates
     */
    refreshTemplateCatalog() {
        if (RightsHelper.isModeDemo()) {
            return this.RequestManager.getJson(config.templatesUrl).then((templateJson) => {
                this.loadTemplates(templateJson);
            });
        }

        if (!RightsHelper.isModePublic() || RightsHelper.isRoleDesigner()) {
            if (config.isLocalTemplatesUrl) {
                return this.RequestManager.getJson(config.templatesUrl).then((templateJson) => {
                    this.loadTemplates(templateJson);
                });
            }
            return this.RequestManager.getJson(config.templatesUrl, true).then((templateJson) => {
                this.loadTemplates(templateJson);
            });
        }
        return Promise.resolve(null);
    }

    /**
     * Run a request to get the JSON infos for the current user's groupedObjects
     */
    refreshGroupObjectsCatalog() {
        if (RightsHelper.isModeDemo()) {
            return this.RequestManager.getJson(config.groupsObjectsUrl).then((groupObjectsJson) => {
                this.loadGroupedObject(groupObjectsJson);
            });
        }

        if (!RightsHelper.isModePublic() || RightsHelper.isRoleDesigner()) {
            if (config.isLocalGroupsObjectsUrl) {
                return this.RequestManager.getJson(config.groupsObjectsUrl).then((groupObjectsJson) => {
                    this.loadGroupedObject(groupObjectsJson);
                });
            }
            return this.RequestManager.getJson(config.groupsObjectsUrl, true).then((groupObjectsJson) => {
                this.loadGroupedObject(groupObjectsJson);
            });
        }
        return Promise.resolve(null);
    }

    getCatalogItemFromRef(ref) {
        return this.products[ref];
    }

    /**
     * Try to load mesh geometry from ref
     * @param {string} ref
     * @returns {Promise || MeshInfo || null} A promise returning the mesh infos
     * for this mesh reference or null
     */
    tryRegisterMeshGeometry(ref) {
        const product = this.products[ref];
        if (!product) {
            throw new Error(`Trying to load inexistant reference ${ref} inside catalog`);
        }
        if (!this.MeshManager.loadedMeshes[ref]) {
            let meshUrl = `${config.amazonS3Url}/${product.url}`;
            if (config.isLocalMeshesUrl) {
                meshUrl = `/assets/${product.url}`;
            }

            this.MeshManager.loadedMeshes[ref] = this.MeshManager.loadMesh(product, meshUrl)
                .then((meshInfos) => meshInfos)
                .catch((e) => {
                    self.app.log.error('Error when trying to load mesh ', e);
                });
            return this.MeshManager.loadedMeshes[ref];
        }

        return Promise.resolve(this.MeshManager.loadedMeshes[ref]);
    }

    /**
     * Add a product to the products list
     * @param {*} product
     */
    registerProduct(product, isAvailable, category, subCategory) {
        const item = new CatalogItem({
            product,
            isAvailable,
            category,
            subCategory,
        });
        this.products[product.ref] = item;
        return item;
    }

    /**
     * Cumulate the constraints in subcategoriesShortNames
     * Use partNumber of the product to get size
     * @param {*} product
     * @param {*} subcategoryName
     * @param {*} categoryName // used for debug and keep some context
     * @returns
     */
    addResizableConstraints(product, subcategoryName, categoryName) {
        // partnumber is like "B 100 100"
        const { partNumber } = product;
        if (!partNumber) {
            return;
        }

        const partNumberSplitted = partNumber.split(' ');
        if (partNumberSplitted.length < 2) {
            return;
        }

        const firstSize = Number(partNumberSplitted[1].replace(',', '.')) / 10000;
        if (Number.isNaN(firstSize)) {
            return;
        }

        let secondSize;
        if (partNumberSplitted.length > 2) {
            secondSize = Number(partNumberSplitted[2].replace(',', '.')) / 10000;
        }

        const subcategoryShortName = subcategoryName.split(' ')[0];

        if (!RESIZABLE_CONSTRAINTS_TEMPLATES[subcategoryShortName]) {
            return;
        }

        let constraints = this.resizableConstraints[subcategoryShortName];
        if (!constraints) {
            const template = RESIZABLE_CONSTRAINTS_TEMPLATES[subcategoryShortName];
            constraints = { ...template, categoryName, subcategoryName };
            this.resizableConstraints[subcategoryShortName] = constraints;
        }

        Object.keys(constraints).forEach((key) => {
            switch (key) {
                case 'MIN_WIDTH':
                case 'MIN_DEPTH':
                    constraints[key] = Math.min(constraints[key], firstSize);
                    break;
                case 'MAX_WIDTH':
                case 'MAX_DEPTH':
                    constraints[key] = Math.max(constraints[key], firstSize);
                    break;
                case 'MIN_HEIGHT':
                    constraints[key] = Math.min(constraints[key], Number.isNaN(secondSize) ? firstSize : secondSize);
                    break;
                case 'MAX_HEIGHT':
                    constraints[key] = Math.max(constraints[key], Number.isNaN(secondSize) ? firstSize : secondSize);
                    break;
                default:
                    break;
            }
        });
    }
}
