import EntityStructure from './entity-structure';
import self from '../..';

const { Entity } = self.app.modules.dataStore;

const TrackLightStructure = EntityStructure.$extend({
    __name__: 'tracklight',

    __init__(params) {
        this.$super(params);
        this.initSnapping();
    },

    __include__: [self.app.modules.snapping.SnappableMixin.prototype],
});
Entity.$register(TrackLightStructure);
export default TrackLightStructure;
