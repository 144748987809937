import EntityStructure from './entity-structure';
import self from '../..';

const { Entity } = self.app.modules.dataStore;

const BackledLightboxStructure = EntityStructure.$extend({
    __name__: 'backled-lightbox',

    __init__(params) {
        this.$super(params);
        this.initOptions();
        this.initMeasurement();
    },

    __include__: [self.app.modules.optionManager.OptionableMixin.prototype, self.app.modules.measurementManager.MeasurableMixin.prototype],
});
Entity.$register(BackledLightboxStructure);
export default BackledLightboxStructure;
