import EntityStructure from './entity-structure';
import self from '../..';

const { Entity } = self.app.modules.dataStore;

const StructuralStructure = EntityStructure.$extend({
    __name__: 'structural',

    __init__(params) {
        this.$super(params);
    },
});
Entity.$register(StructuralStructure);
export default StructuralStructure;
